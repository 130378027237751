const userFeedback = {
  userFeedback: {
    sent: "Feedbacks enviados",
    back: "Volver a Feedback",
    roles: {
      all: "Todos",
      employee: "Colaborador",
      manager: "Manager",
    },
    search: {
      notFound: "No se ha encontrado ningún usuario con ese nombre.",
    },
    header: {
      avatar: "",
      name: "Persona",
      sent: "Enviados",
      received: "Recibidos",
      pending: "Pendientes",
      role: "Rol",
      last: "Último feedback",
      avg: "Feedback promedio",
      graph: "Análisis de feedback",
    },
    evaluations: {
      veryLow: "No cumple con las expectativas",
      low: "Cumple parcialmente las expectativas",
      medium: "Cumple con las expectativas",
      high: "🚀 Excede las expectativas",
      veryHigh: "🦄 Es un referente en la industria",
    },
    detailsModal: {
      title: "Performance feedback",
      searchPlaceholder: "Buscar Persona",
      commentTitle: "Comentario",
      average: "Promedio",
      segment: "Segmento: ",
      comment: "Comment: ",
      start: "Start: ",
      stop: "Stop: ",
      continue: "Continue: ",
      sent: "Total enviados: ",
      pending: "Total pendientes:",
      received: "Total recibidos:",
      tooltip:
        "Únicamente será posible visualizar aquellos feedbacks que hayan sido evaluados utilizando una evaluación numérica del 1 al 5.",
    },
    exportModal: {
      title: "Exportación de feedbacks",
      exportButton: "Exportar",
      startDatePicker: "Desde:",
      endDatePicker: "Hasta:",
      endDatePlaceholder: "Selecciona una fecha de inicio",
      addTeams: "Agregar equipos",
      addProperties: "Agregar propiedades",
      emailLabel: "Ingresa un correo electrónico",
      emailPlaceholder: "ejemplo@correo.com",
      sendButton: "Enviar",
      alertSuccess:
        "¡Se ha enviado con éxito! Esto podría tardar unos minutos.",
      sending: "Enviando...",
      error: {
        NO_EMAIL: "Se requiere dirección de correo electrónico.",
        MISSING_PARAMETERS:
          "Faltan parámetros requeridos. Por favor, contacte a soporte.",
        NO_FEEDBACKS: "No se encontraron feedbacks para esta cuenta.",
        INVALID_DATE: "Fecha inválida.",
        NO_FEEDBACKS_IN_DATERANGE:
          "No se encontraron feedbacks dentro del rango de fechas.",
        INTERNAL_ERROR:
          "Ocurrió un error inesperado, por favor intente nuevamente o contacte a soporte.",
      },
    },
  },
};

export default userFeedback;
