import React from "react";
import LogoLoader from "../Utils/LogoLoader";

const LoadingPage = function () {
  return (
    <div className="bz-flex bz-items-center bz-justify-center bz-h-screen bz-w-screen bz-bg-[#FFFFFF] bz-overflow-auto bz-relative">
      <div className="bz-relative bz-flex bz-flex-col bz-items-center">
        <LogoLoader />
      </div>
    </div>
  );
};

export default LoadingPage;
