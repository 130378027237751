import React from "react";
import organizationLogo from "../../../assets/images/organizationLogo.svg";
import loaderGif from "./circular-loader.gif"; // Path to your uploaded GIF

const LogoLoader = function () {
  return (
    <div
      className="bz-relative bz-h-48 bz-w-48 bz-flex bz-items-center bz-justify-center"
      style={{
        backgroundImage: `url(${loaderGif})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "50%",
      }}
    >
      <img
        src={organizationLogo}
        alt="Organization Logo"
        className="bz-animate-logoloader bz-h-24 bz-w-24 bz-rounded-full"
      />
    </div>
  );
};

export default LogoLoader;
