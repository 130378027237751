const calendar = {
  calendar: {
    event: {
      newEvent: "Nuevo evento",
      editEvent: "Editar evento",
      moreEvent: "evento más",
      moreEvents: "eventos más",
      createEvent: {
        selectDate: "Fecha",
        selectHour: "Hora",
        selectMinutes: "Minutos",
      },
      save: "Guardar",
      type: "Tipo de evento",
      tooltipThereIsRecognition:
        "Recuerda que solo puedes enviar un reconocimiento al mes y solo puedes agendarlo si quedan más de dos días hábiles antes de que termine el mes",
      tooltipAddReport:
        "Los resumenes individuales solo se enviarán a los colaboradores con reconocimientos",
      teams: "Equipos",
      segments: "Segmentos",
      toChoose: "Seleccione grupos o segmentos",
      chooseTemplate: "Seleccione la plantilla",
    },
    createdEvent: {
      message: "¡Evento agendado!",
    },
    updateEvent: {
      message: "¡Evento actualizado!",
      errorMessage: "error",
      updateOne: "Este evento",
      updateAll: "Todos los eventos",
      updateOneTooltip: "Solo aplica a la fecha",
    },
    deleteEvent: {
      message: "¡Evento eliminado!",
      undoDeleted: "Deshacer",
      deleteOne: "Este evento",
      deleteAll: "Todos los eventos",
    },
    toolbar: {
      newEvent: "Nuevo evento",
      month: "Mes",
      week: "Semana",
      day: "Día",
      today: "Hoy",
      list: "Lista",
    },
    types: {
      microSurvey: "Microencuesta",
      feedback: "Feedback organizacional",
      userFeedback: "Feedback",
      customSurvey: "Encuesta personalizada",
      onboarding: "Onboarding",
      recognitionRequest: "Solicitud de reconocimiento",
      recognitionReport: "Resumen individual de reconocimientos",
      randomMeeting: "Random meeting",
      questionsReminder: "Recordatorio de preguntas",
    },
    recognitions: {
      info: "Los reconocimientos se envían solamente una vez al mes",
      infoTwo:
        "Para que los colaboradores reciban sus reconocimientos, debes programar los resumenes individuales 😍",
      dateValidator:
        "El resumen debe ser posterior al pedido por al menos 1 día",
    },
    feedbackCustom: {
      directions: "Orientación",
      titleSelect: "Repetir cada",
      startDate: "Fecha inicial",
      endDate: "Fecha de finalización",
      remenderDate: "Fecha de recordatorio",
      repetFeedback: "Repetir evento cada",
      start: "Selecciona la fecha de inicio",
      end: "Selecciona la fecha de finalización",
      reminder: "Selecciona la fecha de recordatorio",
      monthsOne: "Cada 1 mes",
      monthsTwo: "Cada 2 meses",
      monthsThree: "Cada 3 meses",
      orientation: "Orientación",
    },
    microSurvey: {
      questionsQuantity: "Cantidad de preguntas",
    },
    customSurvey: {
      anonymous: "Es ańonima",
      notAnonymous: "No es anónima",
    },
    randomMeeting: {
      groupSize: "Tamaño de los grupos",
      concurrence: "Recurrencia de los Random Meeting",
      everyWeek: "Cada {{ value }} semana",
      everyWeeks: "Cada {{ value }} semanas",
    },
    audience: {
      title: "Audiencia",
      selectTeam: "Seleccionar equipo",
      selectSegment: "Seleccionar segmento",
      all: "Todos",
      team: "Equipo",
      segment: "Segmento",
      teamSelect: "Selecciona los equipos",
      segmentSelect: "Selecciona los segmentos",
      tooltipTextAudience:
        "El equipo o segmento debe contar con al menos 4 usuarios activos para poder incluirlo en la audiencia",
    },
    repeatEvent: {
      title: "Repetir evento",
      frequencySelect: "Frecuencia",
      everyDay: "Cada día laborable",
      everyWeek: "Cada semana el {{day}}",
      everyTwoWeeks: "Cada dos semanas el {{day}}",
      everyMonth: "Cada mes",
      everyTwoMonths: "Bimestralmente",
      everyThreeMonths: "Cada tres meses",
      everyYear: "Anualmente el {{week}} {{day}} de {{month}}",
      week: {
        1: "primer",
        2: "segundo",
        3: "tercer",
        4: "cuarto",
        5: "quinto",
      },
      day: {
        1: "lunes",
        2: "martes",
        3: "miércoles",
        4: "jueves",
        5: "viernes",
      },
      last: "último",
      custom: {
        title: "Personalizar...",
        repeat: "Repetir cada",
        week: "semana",
        month: "mes",
        year: "año",
        weeks: "semanas",
        months: "meses",
        years: "años",
        itRepeats: "Se repite el",
        errorValidate: "Selecciona mínimo un día",
      },
    },
    sentTo: {
      title: "Enviado a",
      content: "{{sentTo}} colaboradores",
    },
    feedbackTracking: {
      person: "Persona",
      /*       status: "Estado", */
      participation: "Participación",
      percentage: "Porcentaje",
      creationDate: "Fecha de creación",
    },
    date: "Fecha",
  },
};

export default calendar;
